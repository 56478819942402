import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['carouselItem', 'buttonItem']
  static values = { index: Number }

  connect() {
    this.changeButtonClass()
    this.initializeSwiper()
  }

  initializeSwiper() {
    const swiper = new Swiper(".mySwiper", {
      spaceBetween: 30,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      rewind: true
    });
  }

  changeButtonClass() {
    this.buttonItemTargets.forEach((button, index) => {
      if (index === this.indexValue) {
        button.classList.add('active')
      } else {
        button.classList.remove('active')
      }
    })
  }

  clickedItem(event) {
    const index = event.target.getAttribute('index')
    this.indexValue = index
    this.changeButtonClass()
  }

  indexValueChanged() {
    this.showCurrentDisplayItem()
  }

  showCurrentDisplayItem() {
    const totalItems = this.carouselItemTargets.length
    this.carouselItemTargets.forEach((element, index) => {
      if (this.indexValue < 0) {
        this.indexValue = totalItems - 1
      }

      this.indexValue = this.indexValue % totalItems
      element.hidden = index != this.indexValue
    })
  }
}
