import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['carouselItem', 'buttonItem']
    static values = { index: Number }

    connect() {
        this.changeButtonClass()
    }

    changeButtonClass() {
        this.buttonItemTargets.forEach((button, index) => {
            if (index === this.indexValue) {
                button.classList.add('active')
            } else {
                button.classList.remove('active')
            }
        })
    }

    clickHandler(event) {
        const index = event.target.getAttribute('index')
        this.indexValue = index
        this.changeButtonClass()
        this.updateText(index)
    }

    indexValueChanged() {
        this.showCurrentDisplayItem()
    }

    showCurrentDisplayItem() {
        const items = document.getElementsByClassName('xb-section')
        const totalItems = items.length
        Array.from(items).forEach((element, index) => {
            if (this.indexValue < 0) {
                this.indexValue = totalItems - 1
            }

            this.indexValue = this.indexValue % totalItems
            element.hidden = index != this.indexValue
            this.toggleDisplayOfRadioButtons(this.indexValue)
        })

        this.toggleDisplayImages()
    }

    toggleDisplayImages() {
        const items = document.getElementsByClassName('xb-image')
        Array.from(items).forEach((element, index) => {
            element.hidden = !element.hidden
        })
    }


    toggleDisplayOfRadioButtons(index) {
        const deductibleElements = document.getElementsByClassName('deductible')
        if(deductibleElements.length == 0) {
            return;
        }
        let removeIndex = index === 0 ? 1 : 0
        deductibleElements[index].classList.remove('hidden')
        deductibleElements[removeIndex].classList.add('hidden')
    }

    updateText(index) {
      const paragraphElement = document.getElementById('plan_text');
      if (!paragraphElement) {
        return;
      }

      if (index === '1') {
        paragraphElement.innerHTML = 'This coverage option only covers the Battery & Drive Unit(s) for your Tesla.<br><br> 24/7 roadside assistance and rental reimbursement is included with all plans. $500 deductible and $30,000 limit of liability are standard. (Average HV Battery replacement cost ranges from $12k-18k with Tesla Service)<br><br>All XCare plans include Road Assistance and will activate 30 days after purchase.';
      } else {
        let premiumCoverageElement = document.getElementById('premium_coverage');

        if (premiumCoverageElement !== null) {
            paragraphElement.innerHTML = 'Full comprehensive coverage for your EV, which includes the High Voltage Battery &amp; Drive Unit.<br><br>All XCare plans include Road Assistance and will activate 30 days after purchase.';
        } else {
            paragraphElement.innerHTML = 'Comprehensive service coverage for your EV. Based on your vehicle entry, this coverage excludes the High Voltage Battery.<br><br>All XCare plans include Roadside Assistance and will activate 30 days after purchase.'
        }
      }
    }
}
