import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['guarantor']

  remove() {
    // Remove the active class from this guarantor target
    // Destroy value set to 1 so it is not saved
    // Hide the form
    this.guarantorTarget.classList.remove('active')
    this.guarantorTarget.querySelector("input[name*='_destroy']").value = 1
    this.guarantorTarget.style.display = 'none'

    const inputsToHide = this.guarantorTarget.querySelectorAll(
      'select,input:not([type="hidden"],text,date)'
    )
    inputsToHide.forEach((input) => {
      input.style.display = 'none'
    })

    const guarantorCount = Array.from(
      document.getElementsByClassName('nested-guarantor-fields active')
    ).length
    if (guarantorCount < 4) {
      const guarantorDiv = document.getElementById('add-guarantor')
      guarantorDiv.style.display = 'block'
    }
  }
}
