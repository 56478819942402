import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['affiliateTemplate']

  connect() {
    if (document.getElementById('lease_application_affiliate_check_true')) {
      if (
        document.getElementById('lease_application_affiliate_check_true')
          .checked
      ) {
        this.addAssociation()
      }
    }
  }

  addAssociation() {
    const content = this.affiliateTemplateTarget.innerHTML
    this.affiliateTemplateTarget.insertAdjacentHTML('beforebegin', content)

    // Need to check if there are fields in this template to float label after the template has been added to page
    const affiliateFields = document.getElementById('hidden-affiliate-fields')
    const inputs = affiliateFields.querySelectorAll(
      'select,input:not([type="hidden"])'
    )
    inputs.forEach((input) => {
      if (input.value) {
        if (input.previousElementSibling) {
          input.previousElementSibling.classList.add('active')
        }
      }
    })
  }

  removeAssociation() {
    const affiliateFields = document.getElementById('hidden-affiliate-fields')
    affiliateFields.querySelector("input[name*='_destroy']").value = 1
    affiliateFields.style.display = 'none'

    const inputsToHide = affiliateFields.querySelectorAll(
      'select,input:not([type="hidden"],text,date)'
    )
    inputsToHide.forEach((input) => {
      input.style.display = 'none'
    })
  }
}
