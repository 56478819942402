import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['addAccount', 'template', 'form']

  connect() {
    const applicantForm = document.querySelectorAll('.nested-account-fields')
    this.count = applicantForm.length
    if (this.count == 0) {
      this.addAssociation()
      this.checkForCompletedFields()
    } else if (this.count > 1) {
      this.updateAssociationDetails()
      this.checkForCompletedFields()
    }
  }

  addAssociation() {
    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      this.count++
    )

    this.addAccountTarget.insertAdjacentHTML('beforebegin', content)

    if (this.count > 1) {
      this.updateAssociationDetails()
    }
  }

  removeAssociation() {
    if (this.count == 1) return
    let lastElement = Array.from(
      document.querySelectorAll('.nested-account-fields')
    ).pop()
    lastElement
      .querySelectorAll("input[name*='_destroy']")
      .forEach((item) => (item.value = 1))
    lastElement.style.display = 'none'

    const inputsToHide = lastElement.querySelectorAll(
      'select,input:not([type="hidden"],text,date)'
    )
    inputsToHide.forEach((input) => {
      input.style.display = 'none'
    })

    document.getElementById(
      'lease_application_joint_applicant_false'
    ).checked = true
  }

  updateAssociationDetails() {
    document.getElementById(
      'lease_application_joint_applicant_true'
    ).checked = true

    const lastElement = Array.from(
      document.querySelectorAll('.nested-account-fields')
    ).pop()

    const applicantDetails = lastElement.querySelector('#applicantDetails')
    applicantDetails.remove()

    // add primary false value to hidden field
    lastElement.querySelector("input[name*='primary']").value = false

    const contactInfoTitle = lastElement.querySelector('#contactInfoTitle')
    contactInfoTitle.innerHTML = 'Co-applicant Contact Information'

    const employeeInfoTitle = lastElement.querySelector('#employeeInfoTitle')
    employeeInfoTitle.innerHTML = 'Co-applicant Employment Information'
  }

  checkForCompletedFields() {
    const inputs = this.formTarget.querySelectorAll(
      'select,input:not([type="hidden"])'
    )
    inputs.forEach((input) => {
      if (input.value) {
        if (input.previousElementSibling) {
          input.previousElementSibling.classList.add('active')
        }
      }
    })
  }
}
