import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['ssn', 'maskedNumber']

  connect() {
    if (this.ssnTarget.value) {
      this.maskValue(this.ssnTarget.value)
    }
  }

  maskSSN(event) {
    const inputValue = event.target.value
    this.maskValue(inputValue)
  }

  maskValue(value) {
    const length = value.length
    const stars =
      length > 0
        ? length > 1
          ? length > 2
            ? length > 3
              ? length > 4
                ? 'XXX-XX-'
                : 'XXX-X'
              : 'XXX-'
            : 'XX'
          : 'X'
        : ''
    const result = stars + value.substring(5)
    const maskedValue = result.length == 11 ? result : result + '|'
    this.maskedNumberTarget.value = maskedValue
  }
}
