import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { Turbo } from '@hotwired/turbo-rails'
import { debounce } from 'debounce'
window.Turbo = Turbo

export default class extends Controller {
  static targets = [
    'vehicleMake',
    'model',
    'rates',
    'slider',
    'sliderText',
    'residual',
    'agreementConfirmed',
    'submit',
    'captcha',
    'residualSlider',
    'residualSliderText',
    'termMonths',
    'termMiles',
    'warranty',
    'year',
    'addError',
    'yearError',
    'preownedPurchase',
    'newPurchase',
    'preownedLease',
    'newLease'
  ]

  initialize() {
    this.showWarranty = debounce(this.showWarranty.bind(this), 500)
  }

  connect() {
    this.clickCheckedRadio()
  }

  showModels(event) {
    let make = event
      ? event.target.selectedOptions[0].value
      : document.getElementById('lease_application_vehicle_attributes_make')
          .value
    let target = this.modelTarget.id

    get(`/vehicle_rates/models?target=${target}&make=${make}`, {
      responseKind: 'turbo-stream',
    })
  }

  updateYears(event) {
    let model = event
        ? event.target.selectedOptions[0].value
        : document.getElementById('lease_application_vehicle_attributes_model')
            .value

    let target = this.yearTarget.id

    get(`/vehicle_rates/years_for_model?target=${target}&model=${model}`, {
      responseKind: 'turbo-stream',
    })
  }

  showConsumerMaxResidual(event) {
    let termMonths = event.target.selectedOptions[0].value
    let target = this.residualTarget.id

    get(`/lease_applications/consumer_max_residual?target=${target}&months=${termMonths}`, {
      responseKind: 'turbo-stream',
    })
  }

  showCommercialMaxResidual(event) {
    let termMonths = event.target.selectedOptions[0].value
    let target = this.residualTarget.id

    get(`/lease_applications/commercial_max_residual?target=${target}&months=${termMonths}`, {
      responseKind: 'turbo-stream',
    })
  }

  updateSliderInput() {
    this.sliderTextTarget.innerHTML = this.sliderTarget.value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  updateResidualSliderInput() {
    this.residualSliderTextTarget.innerHTML = this.formatPercentageString(
      this.residualSliderTarget.value
    )
  }

  formatPercentageString(value) {
    return (parseFloat(value) * 100).toFixed(0).toString() + '%'
  }

  enableCheckbox() {
    // refactor this to work with any target
    this.agreementConfirmedTarget.disabled = false
    this.agreementConfirmedTarget.readOnly = false

    if(document.getElementById('lease_application_authorization_agreement')){
        if(document.getElementById('lease_application_authorization_agreement').checked === false) {
            document.getElementById('lease_application_authorization_agreement').click()
        }
    } else if (document.getElementById('warranty_quote_authorization_agreement')){
      document.getElementById('warranty_quote_authorization_agreement').removeAttribute("data-action")
      if(document.getElementById('warranty_quote_authorization_agreement').checked === false) {
        const checkbox = document.getElementById('warranty_quote_authorization_agreement')
        checkbox.checked = true;
        let event = new Event('change');
        checkbox.dispatchEvent(event);
      }
    }

    // this assumes only the one error for the checkbox on this page
    const errorDiv = document.querySelector('.error-message')
    if (errorDiv !== undefined) {
      errorDiv.innerHTML = ''
    }
  }

  enableRadioButton() {
    const radioId = "warranty_quote_authorization_agreement_true"
    const radioElement = document.getElementById(radioId)
    radioElement.removeAttribute('disabled')
    radioElement.click()
  }

  enableCheckboxOnly() {
    this.agreementConfirmedTarget.disabled = false
    this.agreementConfirmedTarget.readOnly = false
  }

  enableSubmit(event) {
    if (event.target.readOnly) {
      event.preventDefault()
      // add error message
      const errorDiv = this.addErrorTarget
      const errorMessages = document.querySelectorAll('.error-message');
      errorMessages.forEach(errorMessage => errorMessage.remove());

      errorDiv.insertAdjacentHTML(
        'beforebegin',
        '<div><span class="error-message">You must review the Authorization Agreement before confirming.</span></div>'
      )
    }
    // This only works on checkbox change since I don't know the Stimulus action to call this function
    // There is an option for a callback, but I'm not sure it works with Stimulus
    // https://github.com/ambethia/recaptcha#recaptcha_tags
    if (
      $('#g-recaptcha-response').val() != '' &&
      this.agreementConfirmedTarget.checked
    ) {
      this.submitTarget.disabled = false
    } else {
        this.submitTarget.disabled = true
    }
  }

  showWarranty() {
    let target = this.warrantyTarget.id
    let term_months = this.termMonthsTarget.value
    let term_miles = this.termMilesTarget.value
    let vehicle_rate_id = this.modelTarget.value
    let year = this.yearTarget.value

    if (term_months && term_miles && vehicle_rate_id && year) {
      if (term_miles > 12000 || term_months > 48) {
        get(
          `/warranty_quotes/lease_warranty?target=${target}&term_length=${term_months}&term_miles=${term_miles}&vehicle_rate_id=${vehicle_rate_id}&year=${year}`,
          {
            responseKind: 'turbo-stream',
          }
        )

        this.warrantyTarget.previousElementSibling.classList.add('active')
      } else {
        const warrantyCost = document.getElementById('lease_application[warranty_cost]')
        const option = document.createElement('option')
        warrantyCost.length = 0
        warrantyCost.add(option)
        this.warrantyTarget.previousElementSibling.classList.remove('active')
      }
    }
  }

  validateModelYear() {
    document.getElementById('yearError').textContent = ''
    let target = 'yearError'
    let year = this.yearTarget.value
    let model = this.modelTarget.value

    let make = this.vehicleMakeTarget.value

    if (year && model && make) {
      get(`/vehicle_rates/years_for_model?target=${target}&model=${model}&make=${make}&year=${year}`,
          { responseKind: 'turbo-stream' }
      )
    }
  }

  enableTermMonths(event) {
    let termMonths = []
    switch (event.target.id) {
       case "lease_application_structure_new_lease":
       case "lease_application_structure_preowned_lease":
         termMonths = [24, 36, 48, 60];
         break;
       case "lease_application_structure_new_purchase":
         termMonths = [24, 36, 48, 60, 72];
         break;
       case "lease_application_structure_preowned_purchase":
         termMonths = [24, 36, 48, 60, 72, 84];
         break;
    }

    let termMonthsSelect = this.termMonthsTarget
    termMonthsSelect.innerHTML = ""

    let emptyOption = document.createElement("option");
    emptyOption.value = "";
    emptyOption.label = " ";
    termMonthsSelect.add(emptyOption);

    termMonths.forEach((months, index) => {
      let option = document.createElement("option");
      option.value = months;
      option.text = months;

      // Add 'selected' attribute to the first option
      if (index === 0) {
        //option.selected = true;
      }

      termMonthsSelect.add(option);
    });


    termMonthsSelect.removeAttribute('disabled')
  }

  clickCheckedRadio() {
    let radioInputs = document.querySelectorAll('input[name="lease_application[structure]"]')
    let checkedRadioButton = Array.from(radioInputs).find(input => input.checked);
    if (checkedRadioButton) {
      // If a checked radio button is found, simulate a click event
      checkedRadioButton.click();
    }
  }


}
