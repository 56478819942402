import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['hamburger', 'innerNav']

  connect() {
    useClickOutside(this)
  }

  hamburgerToggle(event) {
    event.preventDefault()
    const hamburger = this.hamburgerTarget
    hamburger.classList.contains('active')
      ? this.setHamburgerInactive(hamburger)
      : this.setHamburgerActive(hamburger)
  }

  setHamburgerActive(hamburger) {
    hamburger.classList.add('active')
    this.innerNavTarget.classList.add('active')
    document.querySelectorAll('nav .nav-list').forEach((navList) => {
      navList.style.display = 'block'
    })
  }

  setHamburgerInactive(hamburger) {
    hamburger.classList.remove('active')
    this.innerNavTarget.classList.remove('active')
    document.querySelectorAll('nav .nav-list').forEach((navList) => {
      navList.style.display = 'none'
    })
  }

  toggleDropdown(event) {
    event.preventDefault()
    const navSibling = event.target.nextElementSibling

    this.hideOtherDropDowns(navSibling)
    navSibling.style.display === 'none' || navSibling.style.display === ''
      ? (navSibling.style.display = 'block')
      : (navSibling.style.display = 'none')
  }

  clickOutside() {
    this.hideAllDropDowns()
  }

  hideAllDropDowns() {
    document.querySelectorAll('.nav-dropdown').forEach((item) => {
      item.style.display = 'none'
    })
  }

  hideOtherDropDowns(dropdown) {
    document.querySelectorAll('.nav-dropdown').forEach((item) => {
      if (item === dropdown) return
      item.style.display = 'none'
    })
  }
}
