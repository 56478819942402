import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'button']

  initialize() {
    this.contentExpanded = true
  }

  showContent(event) {
    this.contentExpanded ? this.expandContent() : this.collapseContent()
    this.contentExpanded = !this.contentExpanded
  }

  collapseContent() {
    this.buttonTarget.classList.remove('active')
    this.contentTarget.style.display = 'none'
  }

  expandContent() {
    this.buttonTarget.classList.add('active')
    this.contentTarget.style.display = 'block'
  }
}
