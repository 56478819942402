import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['carouselItem']
  static values = { index: Number }

  next() {
    this.indexValue++
  }

  previous() {
    this.indexValue--
  }

  indexValueChanged() {
    this.showCurrentDisplayItem()
  }
  showCurrentDisplayItem() {
    const totalItems = this.carouselItemTargets.length
    this.carouselItemTargets.forEach((element, index) => {
      if (this.indexValue < 0) {
        this.indexValue = totalItems - 1
      }
      this.indexValue = this.indexValue % totalItems
      element.hidden = index != this.indexValue
    })
  }
}
