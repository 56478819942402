import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  connect() {
    // if on an edit page, some fields will already have values so need to add the active state
    if (this.hasFormTarget) {
      this.checkForCompletedFields()
    }
  }

  valueChange(event) {
    if (event.target.value === ' ' || !event.target.value) {
      if (event.target.previousElementSibling.classList.contains('active')) {
        this.removeActive(event)
      }
    } else {
      this.addActive(event)
    }
  }

  dateValueFocused(event) {
    this.addActive(event)
  }

  dateValueFocusedOut(event) {
    this.removeActive(event)
  }

  addActive(event) {
    event.target.previousElementSibling.classList.add('active')
  }

  removeActive(event) {
    if (event.target.value === ' ' || !event.target.value) {
      event.target.previousElementSibling.classList.remove('active')
    }
  }

  checkForCompletedFields() {
    const inputs = this.formTarget.querySelectorAll(
      'select,input:not([type="hidden"]),date,text'
    )
    inputs.forEach((input) => {
      if (input.value) {
        if (input.previousElementSibling) {
          input.previousElementSibling.classList.add('active')
        }
      }
    })
  }
}
