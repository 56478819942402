import Jsona from 'jsona'
import isJsonApiValue from './isJsonApiValue'

const dataFormatter = new Jsona()

function convertFromJsonApi(value) {
  return isJsonApiValue(value) ? dataFormatter.deserialize(value) : value
}

export default convertFromJsonApi
