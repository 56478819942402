import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["content", "icon"];

    toggleVisiblity() {
        const icon = this.iconTarget;
        const content = this.contentTarget;

        // Toggle between right and down arrow icons
        if (icon.classList.contains('fa-angle-right')) {
            icon.classList.remove('fa-angle-right');
            icon.classList.add('fa-angle-down');
        } else {
            icon.classList.remove('fa-angle-down');
            icon.classList.add('fa-angle-right');
        }
        $(content).slideToggle(300);
    }
}