import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['garageTemplate']

  connect() {
    if (document.getElementById('lease_application_garage_check_true')) {
      if (
        document.getElementById('lease_application_garage_check_true').checked
      ) {
        this.addAssociation()
      }
    }
  }

  addAssociation() {
    const content = this.garageTemplateTarget.innerHTML
    this.garageTemplateTarget.insertAdjacentHTML('beforebegin', content)

    // Need to check if there are fields in this template to float label after the template has been added to page
    const garageFields = document.getElementById('hidden-garage-fields')
    const inputs = garageFields.querySelectorAll(
      'select,input:not([type="hidden"])'
    )
    inputs.forEach((input) => {
      if (input.value) {
        if (input.previousElementSibling) {
          input.previousElementSibling.classList.add('active')
        }
      }
    })
  }

  removeAssociation() {
    const garageFields = document.getElementById('hidden-garage-fields')
    garageFields.querySelector("input[name*='_destroy']").value = 1
    garageFields.style.display = 'none'

    const inputsToHide = garageFields.querySelectorAll(
      'select,input:not([type="hidden"],text,date)'
    )
    inputsToHide.forEach((input) => {
      input.style.display = 'none'
    })
  }
}
