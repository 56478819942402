import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['addTradeIn', 'tradeInTemplate']

  connect() {
    // if going to trade in edit page and trade in already exists, then show the trade in fields
    if (document.getElementById('lease_application_trade_in_check_true')) {
      if (
        document.getElementById('lease_application_trade_in_check_true').checked
      ) {
        this.addTradeInAssociation()
      }
    }
  }

  addTradeInAssociation(event) {
    const content = this.tradeInTemplateTarget.innerHTML
    this.addTradeInTarget.insertAdjacentHTML('beforebegin', content)

    // Need to check if there are fields in this template to float label after the template has been added to page
    const tradeInFields = document.getElementById('hidden-trade-in-fields')
    const inputs = tradeInFields.querySelectorAll(
      'select,input:not([type="hidden"])'
    )
    inputs.forEach((input) => {
      if (input.value) {
        if (input.previousElementSibling) {
          input.previousElementSibling.classList.add('active')
        }
      }
    })
  }

  removeTradeInAssociation() {
    const tradeInFields = document.getElementById('hidden-trade-in-fields')
    tradeInFields.querySelector("input[name*='_destroy']").value = 1
    tradeInFields.style.display = 'none'
  }
}
