import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    if (document.getElementById('blog_title') !== null) {
        this.generateJsonLdForBlogPost()
    }
  }

  disconnect() {
    const scriptElements = document.querySelectorAll('script[type="application/ld+json"]');
    scriptElements.forEach((scriptElement) => {
      scriptElement.parentNode.removeChild(scriptElement);
    });
  }

  generateJsonLdForBlogPost() {
    const postTitle = document.getElementById('blog_title').textContent;
    const imgSrc = document.getElementsByClassName('blog-head-image')[0].querySelector('img').src;
    const datePublished = document.getElementById('blogPostDate').value;
    const author = document.getElementById('blogPostAuthor').value;
    const description = document.getElementById('blogPostDescription').value;
    const blogSections = document.querySelectorAll(".blog-section");
    let concatenatedText = "";

    // Loop through each blog section and concatenate its text content
    blogSections.forEach((blogSection, index) => {
      concatenatedText += blogSection.textContent;
    });

    const postData = {
      PAGE_URL: window.location.href,
      POST_TITLE: postTitle,
      POST_IMAGE_URL: imgSrc,
      POST_PUBLISHED_DATE: datePublished,
      AUTHOR_NAME: author,
      PUBLISHER_NAME: "xcelerateauto.com",
      PUBLISHER_LOGO_URL: "/assets/logo-20041beb33e286867204e56b0543af764da66c04014acff6dd8d18f638603e14.svg",
      POST_DESCRIPTION: description,
      POST_CONTENT: concatenatedText,
    };

    const jsonLdTemplate = {
                               "@context": "http://schema.org",
                               "@type": "Article",
                               "name": "{{POST_TITLE}}",
                               "image": "{{POST_IMAGE_URL}}",
                               "datePublished": "{{POST_PUBLISHED_DATE}}",
                               "author": {
                                 "@type": "Person",
                                 "name": "{{AUTHOR_NAME}}"
                               },
                               "url": "{{PAGE_URL}}",
                               "articleBody": "{{POST_CONTENT}}"
                            };
    const jsonLdScript = JSON.stringify(jsonLdTemplate).replace(/{{(.*?)}}/g, (_, key) => postData[key]);
    const scriptElement = document.createElement("script");
    scriptElement.type = "application/ld+json";
    scriptElement.textContent = jsonLdScript;
    document.head.appendChild(scriptElement);
  }



}
