import { isPlainObject, has, castArray } from 'lodash'

function hasAll(obj, paths) {
  return paths.every((path) => has(obj, path))
}

// Returns true if a value is an object with json api format.

function isJsonApiValue(value) {
  return (
    isPlainObject(value) &&
    has(value, 'data') &&
    castArray(value.data).every((data) =>
      hasAll(data, ['id', 'type', 'attributes'])
    )
  )
}

export default isJsonApiValue
