import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['link', 'viewMore', 'slider', 'priceText']

    disconnect() {
        sessionStorage.clear(); // Clears all data in sessionStorage
        console.log("Session storage cleared on page refresh or navigation away");
    }

    clickHandler(event) {
        const deductibleValue = event.currentTarget.value
        this.sendGetRequest(deductibleValue)
    }

    // Hack I guess
    clickViewLessCallback(event) {
        sessionStorage.setItem('viewMoreClicked', 'false')
    }

    // Hack I guess
    clickViewMoreCallback(event) {
        sessionStorage.setItem('viewMoreClicked', 'true')
    }

    updateTargetsHref(targets, deductibleValue) {
        targets.forEach((target) => {
            if (!this.isHidden(target)) {
                target.href = this.updateHrefDeductible(target.href, deductibleValue)
            }
        })
    }

    updateHrefDeductible(href, deductibleValue) {
        const baseUrl = href.split("?")[0]
        const json = this.getParamsFromURL(href);
        const updatedHref = this.updateJsonAndConvertToURL(json, deductibleValue);

        return baseUrl + '?' + updatedHref;
    }

    getParamsFromURL(url) {
        const searchParams = new URLSearchParams(new URL(url).search);
        let jsonParams = {};
        for (const [key, value] of searchParams) {
            if (!key.includes("[]")) {
                jsonParams[key] = value;
            } else {
                const strippedKey = key.replace("[]", "");
                if (!jsonParams[strippedKey]) {
                    jsonParams[strippedKey] = [];
                }
                jsonParams[strippedKey].push(value);
            }
        }
        return jsonParams;
    }

    updateJsonAndConvertToURL(json, newDeductible) {
        json['warranty_quote[deductible]'] = newDeductible;
        let searchParams = new URLSearchParams();
        for (const key in json) {
            if (Array.isArray(json[key])) {
                for (const value of json[key]) {
                    searchParams.append(`${key}[]`, value);
                }
            } else if (typeof json[key] === "object") {
                for (const nestedKey in json[key]) {
                    searchParams.append(`${key}[${nestedKey}]`, json[key][nestedKey]);
                }
            } else {
                searchParams.append(key, json[key]);
            }
        }
        return searchParams.toString();
    }

    updateParagraphElementsWithDeductibleValue(deductibleValue) {
        document.querySelectorAll("p").forEach(p => {
            if (p.textContent.includes("Deductible: $") && !this.isHidden(p)) {
                p.textContent = "Deductible: $" + deductibleValue;
            }
        });
    }

    updatePriceElementsWithDeductibleValue(deductibleValue) {
        document.querySelectorAll(".price").forEach(priceElement => {
            if (!this.isHidden(priceElement)) {
                const originalPriceElement = priceElement.children[0];
                const originalPrice = parseInt(originalPriceElement.value.trim().replace(/[$,]/g, ''));
                let newPrice;
                const xcareRadioBtn = document.querySelector('input[name="deductibleXcare"]');

                if (!this.isHidden(xcareRadioBtn)) {
                    switch (deductibleValue) {
                        case "0":
                          newPrice = originalPrice + 100;
                          break;
                        case "100":
                          newPrice = originalPrice;
                          break;
                        case "500":
                          newPrice = originalPrice - 400;
                          break;
                        default:
                          newPrice = originalPrice;
                      }
                  }

                const formattedPrice = '$' + newPrice.toLocaleString();
                priceElement.childNodes[0].nodeValue = formattedPrice;
            }
        });
    }

    isHidden(el) {
        return (el.offsetParent === null)
    }

    sendGetRequest(deductible) {
     // Get the current URL
     const currentUrl = new URL(window.location.href);
     const viewMoreClicked = sessionStorage.getItem('viewMoreClicked');
     const paymentTriggerClicked = sessionStorage.getItem('full_payment');
     const fullPaymentInDB = document.getElementById('full_payment_indicator').value

     currentUrl.searchParams.set('deductible', deductible);
     if (paymentTriggerClicked === null && new URL(currentUrl).searchParams.get('full_payment') === 'true') {
         currentUrl.searchParams.set('full_payment', 'true');
     } else if (paymentTriggerClicked === 'true') {
         currentUrl.searchParams.set('full_payment', 'true');
     } else if (paymentTriggerClicked === 'false'){
         currentUrl.searchParams.set('full_payment', 'false')
     } else if (fullPaymentInDB === 'true') {
         currentUrl.searchParams.set('full_payment', 'true');
     } else {
       currentUrl.searchParams.set('full_payment', 'false');
     }

     if (viewMoreClicked === 'true') {
       currentUrl.searchParams.set('show_more_rates', 'true');
     } else {
       currentUrl.searchParams.delete('show_more_rates');
     }

     window.location.href = currentUrl.toString();
   }

   setSelectPaymentElementsHrefParam() {
        const paymentTriggerClicked = sessionStorage.getItem('full_payment');
        const paymentElements = document.getElementsByClassName('select_payment')
        if (paymentTriggerClicked === 'false') {
            for (let paymentElement of paymentElements) {
                let paymentElementURL = new URL(paymentElement.href)
                paymentElementURL.searchParams.delete('full_payment')
                paymentElementURL.searchParams.delete('warranty_quote[full_payment]')
                paymentElementURL.searchParams.set('warranty_quote[full_payment]', 'false');
                paymentElement.href = paymentElementURL.toString();
            }
        } else {
            for (let paymentElement of paymentElements) {
                let paymentElementURL = new URL(paymentElement.href);
                paymentElementURL.searchParams.delete('full_payment')
                paymentElementURL.searchParams.set('warranty_quote[full_payment]', 'true');
                paymentElement.href = paymentElementURL.toString();
            }
        }
   }

   paymentTrigger() {
       const checkbox = document.querySelector(".slider-checkbox > input")
       let viewMore = document.getElementsByClassName('view-more')[0].childNodes[1]
       this.whichPaymentDivToShowWhenChecked()
       const paymentPlanText = document.getElementById('payment-plan-text')
       const oneTimePaymentText = document.getElementById('one-time-payment-text')

      //console.log(this.linkTargets)
      if(checkbox.checked) {
          let originalHref = document.getElementsByName('originalHref')[0]
          viewMore.href = originalHref.value;
          viewMore.href = viewMore.href + "&full_payment=false";
          paymentPlanText.style.fontWeight = 'bold';
          oneTimePaymentText.style.fontWeight = '';
          sessionStorage.setItem('full_payment', 'false')
      } else {
          viewMore.href = viewMore.href + "&full_payment=true";
          oneTimePaymentText.style.fontWeight = 'bold';
          paymentPlanText.style.fontWeight = '';
          sessionStorage.setItem('full_payment', 'true')
      }
   }

   whichPaymentDivToShowWhenChecked() {
       const priceTargets = document.querySelectorAll(".installmentsPrice")
       const checkbox = document.querySelector(".slider-checkbox > input")
       priceTargets.forEach((priceElement, index) => {
          let originalPrice = document.getElementsByClassName('originalPrice')[index]
          let installmentsPrice = document.getElementsByClassName('installmentsPrice')[index]

          if(checkbox.checked) {
              sessionStorage.setItem('full_payment', 'false')
              originalPrice.style.setProperty('display', 'none')
              installmentsPrice.style.removeProperty('display')
          } else {
              sessionStorage.setItem('full_payment', 'true')
              installmentsPrice.style.setProperty('display', 'none')
              originalPrice.style.removeProperty('display')
          }

          this.setSelectPaymentElementsHrefParam()
      })
   }

}
