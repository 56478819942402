import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'addError', 'button', 'leaseCalc', 'xcare', 'sliderText']

  connect() {
    this.previous_lender_management()
  }

  previous_lender_management() {
      const previous_lender = document.getElementById("lease_application_accounts_attributes_0_previous_lender_attributes_lender_existed_true");
      const previous_lender_false = document.getElementById("lease_application_accounts_attributes_0_previous_lender_attributes_lender_existed_false")

      const previous_business_lender = document.getElementById("lease_application_business_account_attributes_previous_lender_attributes_lender_existed_true")
      const previous_business_lender_false = document.getElementById("lease_application_business_account_attributes_previous_lender_attributes_lender_existed_false")


      if (previous_lender && previous_lender_false.checked !== true) {
          this.hidePreviousLender()
      } else if (previous_lender && previous_lender.checked === true) {
          this.hidePreviousLender()
      } else if (previous_lender && previous_lender.checked !== true) {
          this.showPreviousLender()
      }

      if (previous_business_lender && previous_business_lender_false.checked !== true) {
          this.hidePreviousLender()
      } else if (previous_business_lender && previous_business_lender.checked === true) {
          this.hidePreviousLender()
      } else if (previous_business_lender && previous_business_lender.checked !== true) {
          this.showPreviousLender()
      }
  }

  pageShow() {
    if (this.hasXcareTarget) {
      // make sure button is not in progress and reset the form
      this.buttonTarget.classList.remove('in-progress')
      this.xcareTarget.reset()
      // reset the p tag to 12,000 for the slider 
      this.sliderTextTarget.innerHTML = '12,000'
    }
  }

  submitForm(event) {
    this.submissionInProgress()
    // clear any old validation items if someone hits submit again
    this.removeErrorMessages()
    let isValid = this.validateForm(this.formTarget)
    // If the form is invalid, prevent default on the event
    // so that the form is not submitted
    if (!isValid) {
      event.preventDefault()
      this.cancelSubmit()
      this.scrollToFirstError()
    } else {
      this.formSubmissionTracker()
    }

    if (this.hasLeaseCalcTarget) {
      this.leaseCalcTarget.classList.remove('in-progress')
    }
  }

  submissionInProgress() {
    if (this.buttonTarget) {
      this.buttonTarget.classList.add('in-progress')
    }
  }

  cancelSubmit() {
    if (this.buttonTarget) {
      this.buttonTarget.classList.remove('in-progress')
    }
    if (document.getElementById('g-recaptcha-response')) {
      grecaptcha.reset()
    }
  }

  validateForm() {
    let isValid = true
    const inputs = this.formTarget.querySelectorAll(
      'select,input:not([type="hidden"],text,date)'
    )
    // only want inputs that do not have display value of none. Some of the nested forms set the inputs to display of none
    const filteredInputs = Array.from(inputs).filter(
      (input) => input.style.display !== 'none'
    )

    filteredInputs.forEach((input) => {
      if (input.validationMessage) {
        if (input.classList.contains('ssn')) {
          input.parentElement.classList.add('error')
        }

        input.classList.add('error')
        this.addErrorMessage(input)

        isValid = false
      }

      if (input.id == "lease_application_accounts_attributes_0_date_of_birth") {
          if(input.value.length !== 10) {
            input.classList.add('error')
            this.addErrorMessage(input, "Wrong date format")
            isValid = false
          }
      }

    })

    if (document.getElementById("yearError") && document.getElementById("yearError").textContent !== '') {
          isValid = false
    }

    const dateId = "lease_application_accounts_attributes_0_date_of_birth"
    if (document.getElementById(dateId) && document.getElementById(dateId).length > 0) {
      isValid = false
    }

    return isValid
  }

  removeErrorMessages() {
    const inputs = this.formTarget.querySelectorAll(
      'select,input:not([type="hidden"],text,date)'
    )
    inputs.forEach((input) => {
      if (input.classList.contains('error')) {
        input.classList.remove('error')
        this.removeErrorMessage(input)
      }
    })
  }

  addErrorMessage(input, customErrorMsg) {
    let errorDiv = null
    // this assumes there is an error div right after the input (if not radio)
    // assumes there is a div with the radio button and label together and an error div after that radio input div
    if (input.type === 'radio') {
      errorDiv = input.parentElement.nextElementSibling
    } else {
      errorDiv = input.nextElementSibling
    }

    let validationMessage = null
    if (customErrorMsg !== undefined) {
        validationMessage = customErrorMsg
    } else {
        validationMessage = input.validationMessage
    }

    errorDiv.insertAdjacentHTML(
      'beforebegin',
      `<div class="full-width"><span class="error-message">${validationMessage}</span></div>`
    )
  }

  removeErrorMessage(input) {
    let errorDiv = null

    // this assumes there is an error div right after the input (if not radio)
    // assumes there is a div with the radio button and label together and an error div after that radio input div
    if (input.type === 'radio') {
      errorDiv = input.parentElement.nextElementSibling
    } else {
      errorDiv = input.nextElementSibling
    }

    errorDiv.innerHTML = ''
  }

  scrollToFirstError() {
    const errorElement = document.getElementsByClassName('error-message')
    if (errorElement.length === 0) return
    errorElement[0].scrollIntoView()
    return window.scrollBy(0, -200)
  }

  showPreviousLender() {
    const elemsToHide = document.getElementsByClassName('previoues_lender_input')
    for(let i = 0; i < elemsToHide.length; i++) {
      elemsToHide[i].style.display = ''
    }

    document.getElementById('previous_lease_form').classList.remove('nested-previous-lease')
  }

  hidePreviousLender() {
    const elemsToHide = document.getElementsByClassName('previoues_lender_input')
    for(let i = 0; i < elemsToHide.length; i++) {
      elemsToHide[i].style.display = 'none'
    }

    document.getElementById('previous_lease_form').classList.add('nested-previous-lease')
  }

  formSubmissionTracker() {
      if (window.location.href.endsWith('/xcare')) {
        const checkbox = document.getElementById('warranty_quote_commercial');
        const state = document.getElementById('warranty_quote_account_attributes_state').value;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'xcare_coverage',
          'state': state, // Assuming you want to include the string '{{state}}' as the value
          'xcare_business': checkbox.checked
        });
      } else if (document.querySelector('h1')?.textContent === 'Select your plan') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'x_care_protection', 'step': '2' });
      } else if (document.querySelector('h1')?.textContent === 'Owner Information') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'x_care_protection',
        'step': '2'
        });
      } else if (document.querySelector('h1')?.textContent === 'Car Verification') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'x_care_protection', 'step': '3' });
      } else if (document.querySelector('h3')?.textContent === 'Personal Lease Calculator') {
        const totalDue = document.querySelector('input[name="lease_application[total_signing_cost]"]').value;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'personal_leasing_apply_calc', 'total_due': totalDue });
      } else if (document.querySelector('h3')?.textContent === 'Commercial Lease Calculator') {
        const totalDue = document.querySelector('input[name="lease_application[total_signing_cost]"]').value;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'commercial_leasing_apply_calc', 'total_due': totalDue });
      } else if (document.querySelector('h1')?.textContent === 'Applicant Information') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'personal_credit_app', 'step': '1', 'visitor_type': 'personal'});
      } else if (document.querySelector('h1')?.textContent === 'Credit Information') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'personal_credit_app', 'step': '2', 'visitor_type': 'personal'});
      } else if (document.querySelector('h1')?.textContent === 'Vehicle Information' && document.querySelector('h3')?.textContent !== 'Get A Custom Quote') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'personal_credit_app', 'step': '3', 'visitor_type': 'personal'});
      }
      else if (document.querySelector('h1')?.textContent === 'Review & Confirm' && document.querySelector('h3')?.textContent === 'Personal Credit App') {
        const referralSource = document.getElementById('lease_application_accounts_attributes_0_marketing').value
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
        {'event': 'personal_credit_app_checkout',
        'referral_type': referralSource ,
         'step': '4',
         'visitor_type': 'personal'});
      } else if (document.querySelector('h1')?.textContent === 'Business Information') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'commercial_credit_app', 'step': '1', 'visitor_type': 'commercial'});
      } else if (document.querySelector('h1')?.textContent === 'Guarantor Information') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'commercial_credit_app', 'step': '2', 'visitor_type': 'commercial'});
      } else if (document.querySelector('h1')?.textContent === 'Lender / Bank Information') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'commercial_credit_app', 'step': '3', 'visitor_type': 'commercial'});
      } else if (document.querySelector('h1')?.textContent === 'Vehicle/Equipment Information') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'commercial_credit_app', 'step': '4', 'visitor_type': 'commercial'});
      } else if (document.querySelector('h1')?.textContent === 'Review & Confirm' && document.querySelector('h3')?.textContent === 'Commercial Credit App') {
        const referralSource = document.getElementById('lease_application_business_account_attributes_marketing').value
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
        {'event': 'commercial_credit_app_checkout',
        'referral_type': referralSource ,
         'step': '5',
         'visitor_type': 'commercial'}
         );
      } else if (document.querySelectorAll('input[type="checkbox"][name="custom_quote[inquiry_types][]"]')[0] !== undefined) {
        const checkboxes = document.querySelectorAll('input[type="checkbox"][name="custom_quote[inquiry_types][]"]');
        const checkedValues = [];
        checkboxes.forEach((checkbox) => {
          if (checkbox.checked) {
            checkedValues.push(checkbox.value);
          }
        });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'custom_quote_app', 'step': '1', 'inquiry_type': checkedValues})
      } else if (document.querySelector('h1')?.textContent === 'Vehicle Information' && document.querySelector('h3')?.textContent === 'Get A Custom Quote') {
        const referralSource = document.getElementById('custom_quote_account_attributes_marketing').value
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
        {'event': 'custom_quote_app_complete',
        'step': '2',
        'referral_type': referralSource}
        );
      } else if (document.querySelector('h3')?.textContent === 'Contact Us') {
        const referralSource = document.getElementById('marketing').value
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(
        {'event': 'contact_us',
        'referral_type': referralSource}
        );
      }

  }


}
