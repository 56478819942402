import { Controller } from "stimulus"

export default class extends Controller {

    connect() {
      // Add event listener for clicks on the window
      window.addEventListener('click', this.windowClickHandler.bind(this))
    }

    disconnect() {
      // Remove event listener when the controller is disconnected
      window.removeEventListener('click', this.windowClickHandler.bind(this))
    }

  open() {
    this.element.classList.add("show");
  }

  close() {
    this.element.classList.remove("show");
  }

    windowClickHandler(event) {
      // Check if the click was outside the modal content
      if(event.target.getAttribute('data-action') === 'click->open#openModal') {
        return
      }

      if (!Boolean(event.target.closest(".modal-content"))) {
        this.close();
      }
    }
}
