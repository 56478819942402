import { Controller } from 'stimulus'

export default class extends Controller {

    connect() {
        window.addEventListener("scroll", this.doOnScroll);
    }

    disconnect() {
        window.removeEventListener("scroll", this.doOnScroll);
    }

  doScroll() {
    let windowHeight = window.innerHeight;
      let scrollPosition = window.scrollY;
      let bodyHeight = document.body.scrollHeight;
      let scrollButton = document.getElementById('scroll-down-btn');

      if (bodyHeight - scrollPosition < windowHeight * 2) {
        scrollButton.classList.add('invisible');
      }
        // Disable button during scroll animation
        scrollButton.disabled = true;

        window.scrollBy({
          top: windowHeight,
          behavior: 'smooth'
        });
  }

  doOnScroll() {
     let windowHeight = window.innerHeight;
     let scrollPosition = window.scrollY;
     let bodyHeight = document.body.scrollHeight;
     let scrollButton = document.getElementById('scroll-down-btn');
     if (bodyHeight - scrollPosition <= windowHeight) {
        scrollButton.classList.add('invisible');
     } else if (bodyHeight - scrollPosition === bodyHeight) {
        scrollButton.classList.remove('invisible');
     }
  }

  debounce(func, delay) {
      let timeoutId;
      return function(...args) {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
              func.apply(this, args);
          }, delay);
      };

}
}
