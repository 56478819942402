import { Controller } from 'stimulus'
import { convertFromJsonApi } from 'utils'

export default class extends Controller {
  static targets = [
    'cardElement',
    'clientSecret',
    'submitButton',
    'nameInput',
    'paymentMessage',
    'paymentElement',
    'amount',
    'paymentIntentId',
    'referralCode',
    'referralButton',
    'displayAmount',
    'quoteId',
    'quoteUuid',
    'adminPath',
    'addReferralError',
    'stripePublishableKey',
  ]

  connect() {
    this.stripe = Stripe(this.stripePublishableKeyTarget.value)
    const appearance = {
      theme: 'stripe',
      labels: 'floating',
    }

    const clientSecret = this.clientSecretTarget.value
    this.elements = this.stripe.elements({ appearance, clientSecret })

    const paymentElement = this.elements.create('payment', {
      fields: { billingDetails: { name: 'auto' } },
    })
    paymentElement.mount(this.paymentElementTarget)

    this.nameInput = this.nameInputTarget
    this.submitButton = this.submitButtonTarget
    this.referralButton = this.referralButtonTarget
    this.buttonText = this.buttonTextTarget
    this.paymentMessage = this.paymentMessageTarget
    this.adminPath = this.adminPathTarget

    // used for referral codes
    this.paymentIntentId = this.paymentIntentIdTarget
    this.referralCode = this.referralCodeTarget
    this.amount = this.amountTarget
    this.quoteId = this.quoteIdTarget
    this.quoteUuid = this.quoteUuidTarget

    this.referralCodeId = null
    this.updatedAmount = null
    this.errorMsg = null
  }

  submitCode(event) {
    event.preventDefault()
    this.disableSubmitButton(true)
    this.setReferralLoading(true)
    if (document.getElementById('stateCode').value === "FL") {
      document.getElementById('referral_error').hidden = false;
      this.setReferralLoading(false)
    } else {
      this.useReferralCode()
    }
  }

  submitForm(event) {
    event.preventDefault()
    //let nameInput = document.getElementById("name");
    //if (nameInput.value.trim() === "") {
    //  document.getElementById("name_error").removeAttribute("hidden")
    //  return false;
    //}
    this.setLoading(true)
    this.submitStripePayment()
  }

  async useReferralCode() {
    const referralRedemption = await fetch('/referrals', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        referral_code_redemption: {
          payment_intent_id: this.paymentIntentId.value,
          referral_code: this.referralCode.value,
          amount: this.amount.value,
          warranty_quote_uuid: this.quoteUuid.value
        },
      }),
    })
      .then((r) => r.json())
      .catch(() =>
        this.showMessage('There was an error processing the referral code.')
      )

    const redemptionData = convertFromJsonApi(referralRedemption)
    if (redemptionData) {
      this.referralCodeId = redemptionData.id
      this.updatedAmount = redemptionData.updated_amount
      this.disableSubmitButton(false)
      this.setReferralSuccess(true)

      if (redemptionData.error_message.length > 0) {
        alert(redemptionData.error_message)
      }
    } else {
      this.disableSubmitButton(false)
      this.setReferralLoading(false)
      this.showMessage('Referral Code is Invalid.')
    }

  }

  async submitStripePayment() {
    const elements = this.elements
    const returnUrl = this.adminPath.value
      ? process.env.APP_ADMIN_QUOTES_URL
      : `${process.env.APP_CHECKOUT_SUCCESS_URL}/${this.quoteUuid.value}`
    await this.stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl,
          payment_method_data: {
            billing_details: {
              name: this.nameInput.value,
            },
          },
        },
        redirect: 'if_required',
      })
      .then((result) => {
        if (result.paymentIntent) {
          this.createPayment(result.paymentIntent)
          this.formSubmissionTracker()
        } else {
          this.setLoading(false)
          this.showMessage('Something went wrong during payment.')
        }
      })
      .catch((error) => {
        this.setLoading(false)
        this.showMessage(error.message)
      })
  }

  async createPayment(paymentIntent) {
    const { error: checkoutError } = await fetch('/checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        payment_intent: paymentIntent,
        referral_code_id: this.referralCodeId,
        quote_id: this.quoteId.value,
        quote_uuid: this.quoteUuid.value,
      }),
    }).then((r) => r.json())
    if (checkoutError) {
      this.setLoading(false)
      this.showMessage(checkoutError.message)
      return
    } else {
      const returnUrl = this.adminPath.value
        ? process.env.APP_ADMIN_QUOTES_URL
        : `${process.env.APP_CHECKOUT_SUCCESS_URL}/${this.quoteUuid.value}`
      return (window.location.href = returnUrl)
    }
  }

  setLoading(loading) {
    if (loading) {
      this.submitButton.disabled = true
      this.submitButton.classList.add('in-progress')
    } else {
      this.submitButton.classList.remove('in-progress')
      this.submitButton.disabled = false
    }
  }

  disableSubmitButton(loading) {
    if (loading) {
      this.submitButton.disabled = true
    } else {
      this.submitButton.disabled = false
    }
  }

  setReferralLoading(loading) {
    if (loading) {
      this.referralButton.disabled = true
      this.referralButton.classList.add('in-progress')
    } else {
      this.referralButton.classList.remove('in-progress')
      this.referralButton.disabled = false
    }
  }

  setReferralSuccess(success) {
    if (success) {
      this.referralButton.disabled = true
      this.referralButton.classList.remove('in-progress')
      this.referralButton.classList.add('success')
      const options = { style: 'decimal' }
      this.displayAmountTarget.innerText = this.displayAmount()
      document.getElementById('display_amount_span').innerText = "Pay " + this.displayAmount() + " now"
      document.getElementById('total_amount').innerText = this.displayAmount()
      document.getElementById('due_today_amount').innerText = this.displayAmount()
    }
  }

  displayAmount() {
    return `$${(this.updatedAmount / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
  }

  showMessage(messageText) {
    const messageContainer = this.paymentMessage

    messageContainer.classList.remove('hidden')
    messageContainer.innerText = messageText

    setTimeout(function () {
      messageContainer.classList.add('hidden')
      messageContainer.innerText = ''
    }, 4000)
  }

  formSubmissionTracker() {
    const amount = document.querySelector('span[data-stripe-target="displayAmount"]').textContent.trim();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'event': 'x_care_protection_checkout', 'amount': amount });
  }
}
